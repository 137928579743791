.color-primary {
  color: #DC1414;
}

.color-secondary {
  color: #191B22;
}

.color-text {
  color: #84858A;
}

.color-accent {
  color: #AE1818;
}

.color-tertiary {
  color: #AAABB0;
}

.color-background {
  color: #ffffff;
}

.color-alternate {
  color: #F5F5F5;
}

.color-border {
  color: #CED0D6;
}



.menu-item-has-children>a:after {
  display: inline-block;
  margin-left: .55em;
  content: "\f107";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  transition: transform 0.4s ease;
}

.menu-item-has-children:hover>a:after {
  transform: rotate(180deg);
}

.nav-item {
  position: relative;
}

/* Accprdion */
.accordion .card {
  display: flex;
  flex-wrap: wrap;
}

.accordion .card .collapseparent {
  order: 2;
}

.accordion .card .card-header {
  width: 100%;
  flex-basis: 100%;
  order: 1;
  padding: 0;
  border-radius: 0;
}

.faqs-accordion .card .card-header .collapsebtn:after {
  content: 'View';
  color: #191B22;
}

.accordion .card .collapseparent.show+.card-header .collapsebtn:after {
  content: 'Close';
}

.accordion>.card:not(:last-of-type) {
  border-bottom: 1px solid #191B22;
}

.sidebar_wrap .sidebar .sidebar_widgets .popular_post li .post .post-wrapper .popular_post_img {
  height: 90px;
}

.user-info-tabs .step-app .step-steps>li.go2998626915>a>.number:before,
.user-info-tabs .step-app .step-steps>li.go2998626915>a>.number:before {
  content: '\f00c';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #fff;
}

.user-info-tabs .step-app .step-steps>li.go2998626915>a>.number,
.user-info-tabs .step-app .step-steps>li.go2998626915>a>.number {
  background-color: #DC1414;
}

.user-info-tabs .step-app .step-steps>li.go2998626915>a>.step-name,
.user-info-tabs .step-app .step-steps>li.go2998626915>a>.step-name {
  color: #DC1414;
}

.user-info-tabs .step-app .step-steps>li.go2998626915:before {
  background-color: #DC1414;
}

.extra-page {
  height: 100vh;
}

.overlapsec+.sub-header .section-padding {
  padding-bottom: 160px;
}

.user-dashboard-sec .dashboard-first-sec .left-side h5:after {
  display: none;
}

.user-dashboard-sec .dashboard-first-sec .left-side h5 a:after {
  display: inline-block;
  margin-left: .55em;
  content: "\f107";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #191B22;
  font-size: 22px;
  line-height: 1.2;
  position: relative;
  top: 2px;
  transform: rotate(180deg);
  transition: 0.3s;
}

.user-dashboard-sec .dashboard-second-sec[aria-expanded="false"]:after,
.user-dashboard-sec .dashboard-first-sec .left-side h5 a[aria-expanded="false"]:after {
  transform: none;
}

.user-dashboard-sec .dashboard-second-sec[aria-expanded="false"] .right-side {
  opacity: 1;
}

.user-dashboard-sec .dashboard-second-sec-large .left-side .pie-chart-area .active-border,
.user-dashboard-sec .dashboard-second-sec .right-side .pie-chart-box .active-border {
  background: none;
}

.user-dashboard-sec .dashboard-second-sec-large .left-side .pie-chart-area .circle,
.user-dashboard-sec .dashboard-second-sec .right-side .pie-chart-box .circle {
  top: 0;
  left: 0;
  width: auto;
  height: auto;
}

@media (max-width:991px) {
  .menu-item-has-children>a {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: 460px) {
  .overlapsec+.sub-header .section-padding {
    padding-bottom: 80px;
  }
}

.alert.d-block {
  display: block !important;
}

/*Whatsapp Icon Float*/
.float-icon-wsp {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
  width: 50px;
  height: 50px;
  background: #fff;
  display: flex;
  margin-right: 1rem;
  margin-bottom: 5rem;
  border-radius: 50px;
  padding: 5px;
  transition: none;
}

.float-icon-wsp:hover img {
  /* filter: invert(13%) sepia(99%) saturate(4094%) hue-rotate(349deg) brightness(52%) contrast(108%); */
  /* filter: invert(8%) sepia(90%) saturate(4424%) hue-rotate(346deg) brightness(127%) contrast(122%); */
}

/**Custom BTN**/
.bank-font {
  font-family: "Inter-Regular";
}

.bank-login-button {
  background-color: #DC1414;
  border: #DC1414;
  color: #ffffff;
  border-radius: 0px;
  font-family: "Inter-Regular";
}

.bank-login-button:hover {
  background-color: #AE1818;
  border: #AE1818;
  color: #ffffff;
}

.text-red{
  color: #DC1414;
}

.nav-tabs{
  background-color: #DC1414;
  color: #ffffff;
}

.nav-tabs{
  background-color: #DC1414;
  color: #ffffff;
}
.nav-tabs a{
  color: #ffffff;
  border: unset !important;
}



.nav-tabs .nav-link.active, .nav-tabs .nav-link:hover{
  background-color: #AE1818;
  /* border: #AE1818; */
  border-left:unset ;
  border-right:unset ;
  border-top:unset ;
  border-bottom:unset ;
  color: #ffffff;
}

.table td, .table th{
  padding: .5rem;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
  color: #AE1818 !important;
  background-color: white;
  /* border: #AE1818; */
  border-left:unset ;
  border-right:unset ;
  border-top:unset ;
  border-bottom:unset ;
  color: #ffffff;
}


.header{
  background-color: #DC1414;
  padding: 0.3rem 1rem;
}
.header > div{
  display: flex;
  justify-content:space-between;
  align-items:center;
}

.header img{
  max-width: 200px;
  max-height: 35px;
}

.header .right-side a{
  color: white;
  font-size: 12pt!important;
  text-wrap: nowrap;
}

.header .right-side img{
  width: 25px!important;
}

.main-body{
  display: flex;
    flex-direction: column;
    min-height: 100vh;
}


.main-body > .container{
  flex-grow: 1;
}

.right-side-content{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.right-side-content nav a:hover{
  color: #F5F5F5;
}

.right-side-content nav a{
  color: white;
  padding: 1rem;
  font-size: 10pt;
  cursor: pointer;
}

.bank-button-cancel{
  background-color: #F5F5F5;
  color: #191B22;
}

.bank-button-cancel:hover{
  color: #F5F5F5;
  background-color: #191B22;
}

/*END Whatsapp Icon Float*/